import { RecipeInfo } from '~/routes/RecipePage/recipe.types'
import { Box, List, ListItem, Text } from '@butcherbox/freezer'
import React from 'react'
import * as Styles from './RecipePage.css'

export const RecipeIngredients = ({ recipe }: { recipe: RecipeInfo }) => {
  const {
    recipe: { ingredientGroups },
  } = recipe

  return (
    <Box className={Styles.ColWithGap[24]} marginBottom={32} marginTop={16}>
      <Box className={Styles.SectionHeader}>
        <Text variant="H2Bold">Ingredients</Text>
      </Box>

      <Box className={Styles.ColWithGap[24]}>
        {ingredientGroups.map((group) => (
          <Box key={group.name}>
            {group.name && (
              <Text marginBottom={12} variant="H3Bold">
                {group.name}
              </Text>
            )}

            <List variant="unordered">
              {group.ingredients.map((ingredient) => (
                <ListItem key={ingredient.id}>
                  <Text variant="H3Regular">
                    {ingredient.amount} {ingredient.unit} {ingredient.name}
                  </Text>
                </ListItem>
              ))}
            </List>
          </Box>
        ))}
      </Box>
    </Box>
  )
}
