import {
  rem,
  Box,
  Button,
  IconHeartFilled,
  IconHeartOutline,
} from '@butcherbox/freezer'
import { LayoutContext } from 'design/contexts/layout'
import React from 'react'
import { JustCookRecipe } from '~/bb-api/schemata'
import useSavedRecipeIds from '~/hooks/useSavedRecipeIds'
import { RecipeInfo } from '~/routes/RecipePage/recipe.types'

const FullHeart = () => (
  <IconHeartFilled customColor={{ base: 'spicedCrimson' }} size="text" />
)
const EmptyHeart = () => (
  <IconHeartOutline customColor={{ base: 'spicedCrimson' }} size="text" />
)

const printUrlForRecipe = (recipeId: number) =>
  `https://justcook.butcherbox.com/wprm_print/${recipeId}`

export const RecipePageCTAs = ({ recipe }: { recipe: RecipeInfo }) => {
  const { toggleRecipeSaved, getIsRecipeSaved } = useSavedRecipeIds()
  const printUrl = printUrlForRecipe(recipe.recipe.recipeId)
  const idPayload = { id: recipe.postId } as JustCookRecipe
  const { isMobile } = React.useContext(LayoutContext)

  return (
    <Box display="flex" flexWrap="wrap" gap={16}>
      <Box style={{ maxWidth: isMobile ? 'unset' : rem(340) }}>
        <Button
          key={recipe.postId}
          onClick={() => toggleRecipeSaved(idPayload)}
          width="fluid"
        >
          Save this recipe
          <Box display={'inlineFlex'} paddingLeft={4}>
            {getIsRecipeSaved(idPayload) ? <FullHeart /> : <EmptyHeart />}
          </Box>
        </Button>
      </Box>
      <Button
        component="a"
        data-what="print-recipe"
        href={printUrl}
        target="_blank"
        variant="secondary"
        width="compact"
      >
        Print
      </Button>
    </Box>
  )
}
