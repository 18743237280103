import { RecipeInfo } from '~/routes/RecipePage/recipe.types'
import React from 'react'
import { LayoutContext } from 'design/contexts/layout'
import CondensedWithShowMore from '~/components/CondensedWithShowMore/CondensedWithShowMore'

export const RecipeImage = ({ recipe }: { recipe: RecipeInfo }) => (
  <img alt={recipe.title.rendered} src={recipe.recipe.image_url} />
)

export const RecipeDescription = ({ recipe }: { recipe: RecipeInfo }) => {
  const { isMobile } = React.useContext(LayoutContext)

  return (
    <CondensedWithShowMore maxLength={isMobile ? 150 : 300} variant="H3Regular">
      {recipe.description.rendered}
    </CondensedWithShowMore>
  )
}
