import { pluralize } from 'design'
import { RecipeInfo } from '~/routes/RecipePage/recipe.types'
import { Box, Text } from '@butcherbox/freezer'
import { ColWithGap } from '~/routes/RecipePage/RecipePage.css'
import React from 'react'

const formatDuration = (minutes: string) => {
  const min = Number(minutes)
  const hours = Math.floor(min / 60)
  const mins = min % 60
  const minsStr = `${mins} ${pluralize(mins, 'minute')}`
  const hoursStr = `${hours} ${pluralize(hours, 'hour')}`

  if (!hours) return minsStr
  if (!mins) return hoursStr

  return `${hoursStr} ${minsStr}`
}

const getRecipeInfo = ({ recipe }: RecipeInfo) => ({
  protein: recipe.tags.protein[0].name,
  cut: recipe.tags.cut[0].name,
  course: recipe.tags.course[0].name,
  'prep time': formatDuration(recipe.prep_time),
  'cook time': formatDuration(recipe.cook_time),
  'total time': formatDuration(recipe.total_time),
  servings: `${recipe.servings} ${recipe.servings_unit}`,
})

// todo: this should probably be List/ListItem but those have
//  annoying prop types so just using Box for now.
export const RecipeStats = ({ recipe }: { recipe: RecipeInfo }) => (
  <Box background={'ivory'} className={ColWithGap[8]} p={16}>
    {Object.entries(getRecipeInfo(recipe)).map(([key, value]) => (
      <Box display="flex" gap={16} key={key}>
        <Text textTransform={'capitalize'} variant="H3Bold">
          {key}
        </Text>
        <Text variant="H3Regular">{value}</Text>
      </Box>
    ))}
  </Box>
)
