import React from 'react'
import { CardGrid } from '@butcherbox/freezer'
import RecipeList from '~/components/RecipeList/RecipeList'
import RecipesLoading from '~/components/ReipesLoading/RecipesLoading'
import { RecipeGridUIProps } from '~/components/RecipeList/RecipeList.types'

const RecipeGridUI = ({
  columns = { desktop: 3, tablet: 2, mobile: 1 },
  gridGap = 16,
  isLoading,
  ...recipeListProps
}: RecipeGridUIProps) => {
  if (isLoading) return <RecipesLoading />

  return (
    <CardGrid columns={columns} gap={gridGap} justifyItems="center">
      <RecipeList {...recipeListProps} />
    </CardGrid>
  )
}

export default RecipeGridUI
