import { RecipeInfo } from '~/routes/RecipePage/recipe.types'
import { Box } from '@butcherbox/freezer'
import * as Styles from '~/routes/RecipePage/RecipePage.css'
import React from 'react'
import {
  RecipeByline,
  RecipeTitle,
  RecipeTitleAndInfo,
} from '~/routes/RecipePage/RecipeTitleAndInfo'
import {
  RecipeDescription,
  RecipeImage,
} from '~/routes/RecipePage/RecipeImageAndDescription'
import { RecipeIngredients } from '~/routes/RecipePage/RecipeIngredients'
import { RecipeInstructions } from '~/routes/RecipePage/RecipeInstructions'
import { RecipeStats } from '~/routes/RecipePage/RecipeStats'
import { LayoutContext } from 'design/contexts/layout'
import { RecipePageCTAs } from '~/routes/RecipePage/RecipePageCTAs'

/**
 * TODO
 * For the first rushed iteration, we used a bunch of crappy cheating margins and padding
 * and extra boxes to make spacings work. When we come back to this we should definitely
 * work on making them better. Mainly with regards to Panel Break spacing, sort of?
 * Consider: RecipeUIMobile could have all the components inside a ColWithGap box?
 * But then ingredients and instructions shouldn't have their own padding?
 * But do we perhaps want them to have their own padding, since they are very much sections?
 */

export const RecipeUIDesktop = ({ recipe }: { recipe: RecipeInfo }) => (
  <>
    <Box background="white" className={Styles.Page}>
      <Box className={Styles.Content}>
        <Box className={Styles.RecipeUIDesktop}>
          <RecipeTitleAndInfo recipe={recipe} />

          <Box className={Styles.ColWithGap[24]}>
            <RecipeImage recipe={recipe} />
            <RecipeDescription recipe={recipe} />
            <RecipePageCTAs recipe={recipe} />
          </Box>
        </Box>
      </Box>
    </Box>

    <Box background="ivory" className={Styles.Page}>
      <Box className={Styles.Content}>
        <Box className={Styles.RecipeUIDesktop}>
          <RecipeIngredients recipe={recipe} />
          <RecipeInstructions recipe={recipe} />
        </Box>
      </Box>
    </Box>
  </>
)

export const RecipeUIMobile = ({ recipe }: { recipe: RecipeInfo }) => (
  <>
    <Box background="white" className={Styles.Page}>
      <Box className={Styles.Content}>
        <Box className={Styles.ColWithGap[24]} marginBottom={24}>
          <RecipeTitle recipe={recipe} />
          <RecipeImage recipe={recipe} />
          <RecipeDescription recipe={recipe} />
          <RecipeStats recipe={recipe} />
          <RecipePageCTAs recipe={recipe} />
        </Box>
      </Box>
    </Box>
    <Box background="ivory" className={Styles.Page}>
      <Box className={Styles.Content}>
        <RecipeIngredients recipe={recipe} />
        <RecipeInstructions recipe={recipe} />
        <RecipeByline recipe={recipe} />
      </Box>
    </Box>
  </>
)

const RecipeUI = ({ recipe }: { recipe: RecipeInfo }) => {
  const { isMobile } = React.useContext(LayoutContext)

  return isMobile ? (
    <RecipeUIMobile recipe={recipe} />
  ) : (
    <RecipeUIDesktop recipe={recipe} />
  )
}

export default RecipeUI
