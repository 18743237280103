import React from 'react'
import RecipePageUI from '~/routes/RecipePage/RecipePage.ui'
import { recipes } from '~/routes/RecipePage/fixtures/recipe.fixture'
import { RouteComponentProps, useParams } from '@reach/router'
import { recipeDeals } from '~/routes/RecipePage/fixtures/deal.fixture'
import { navigate } from 'gatsby'

const RecipePage: React.FC<RouteComponentProps> = () => {
  const { slug } = useParams()
  const recipe = recipes[slug]
  const deal = recipeDeals[slug]

  if (!recipe) {
    navigate('/account/recipes')
    return null
  }

  return <RecipePageUI deal={deal} recipe={recipe} />
}

export default RecipePage
