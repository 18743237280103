/* notes for future use
  using the slug we can get on the browse recipe page, we can use that to get the recipe data
  - just need to add wprm- to the slug to make the following query
 
  https://justcook.butcherbox.com/wp-json/wp/v2/wprm_recipe/?slug=wprm-smoky-chili-rubbed-coulotte-with-fancy-red-pepper-salsa
  &_fields=title.rendered,recipe.image_url,recipe.servings,...
 
  we could then make the url something like this
  - /recipe/smoky-chili-rubbed
  or
  - /recipe/smoky-chili[param: wp api ref]
  note: we do not get the description back from the recipe api call it has to come from the posts call

  ------
  
  we really need to be thinking about the *post* more than the *recipe*.
  - the author and description are part of the post, they are NOT in the recipe API response
  - we want the image url from the post, which has the aspect ratio we want (it's the hero image for the post). the
   image from the recipe is the little
   square pic that appears in the actual recipe
  - saved recipes deals with postId, not recipeId.
    - saved recipes is technically "Saved posts" (which means useGetJustCookRecipes is technically
     useGetJustCookPosts, etc...)
*/
import { RecipeInfo } from '~/routes/RecipePage/recipe.types'

const AvailableRecipeSlugs = [] as const

export type AvailableRecipe = typeof AvailableRecipeSlugs[number]

export const recipes: Record<AvailableRecipe, RecipeInfo> = {}
