import { RecipeInfo } from '~/routes/RecipePage/recipe.types'
import { Box, Text } from '@butcherbox/freezer'
import React from 'react'
import dayjs from 'dayjs'
import { RecipeStats } from '~/routes/RecipePage/RecipeStats'

export const RecipeTitle = ({ recipe }: { recipe: RecipeInfo }) => (
  <Text variant="H1Bold">{recipe.title.rendered}</Text>
)

const BylineText = (props) => (
  <Text color={'stone'} variant="Body2Bold" {...props} />
)
export const RecipeByline = ({ recipe }: { recipe: RecipeInfo }) => {
  const [written, updated] = [recipe.date, recipe.modified].map((d) =>
    dayjs(d).format('MMM D, YYYY')
  )

  return (
    <Box>
      <BylineText>
        Written by: {recipe.author} on {written}
      </BylineText>
      <BylineText>Last updated: {updated}</BylineText>
    </Box>
  )
}

// for convenience and spacing, so we don't have to do all this in RecipeUIDesktop
export const RecipeTitleAndInfo = ({ recipe }: { recipe: RecipeInfo }) => (
  <Box
    display="flex"
    flexDirection="column"
    gap={16}
    paddingBottom={20}
    paddingTop={28}
  >
    <Box display="flex" flexDirection="column" gap={16}>
      <RecipeTitle recipe={recipe} />
      <RecipeByline recipe={recipe} />
    </Box>

    <RecipeStats recipe={recipe} />
  </Box>
)
