import { RecipeInfo } from '~/routes/RecipePage/recipe.types'
import { Box, List, Text } from '@butcherbox/freezer'
import * as Styles from '~/routes/RecipePage/RecipePage.css'
import React from 'react'

export const RecipeInstructions = ({ recipe }: { recipe: RecipeInfo }) => {
  const {
    recipe: { instructionGroups },
  } = recipe

  return (
    <Box className={Styles.ColWithGap[24]} marginTop={16}>
      <Box className={Styles.SectionHeader}>
        <Text variant={'H2Bold'}>Instructions</Text>
      </Box>

      <Box className={Styles.ColWithGap[24]}>
        {instructionGroups.map((group) => (
          <Box key={group.name}>
            {group.name && (
              <Text marginBottom={12} variant="H3Bold">
                {group.name}
              </Text>
            )}

            <List className={Styles.ColWithGap[24]} variant="ordered">
              {group.instructions.map((instruction, i) => (
                <Box key={i}>
                  <Text marginBottom={8} variant="Subhead1">
                    Step {i + 1}
                  </Text>
                  <Text component="li" variant="H3Regular">
                    {instruction.text}
                  </Text>
                </Box>
              ))}
            </List>
          </Box>
        ))}
      </Box>
    </Box>
  )
}
