import { Button, Text, TextProps } from '@butcherbox/freezer'
import React from 'react'
import { trimToLength } from '~/utils/trimToLength'

export type ShowMoreProps = {
  maxLength: number
  showMoreText?: string
  showLessText?: string
  // accepts a single string child only
  children: string
} & TextProps

/**
 * Shows a truncated version of its text children, with a customizable "Show More/Less" button.
 *
 * If the child string is actually raw HTML (such as from Wordpress content with a `rendered` property),
 * the HTML will be rendered in a `span` tag using the `dangerouslySetInnerHTML` prop. (the length of
 * the entire raw HTML will be considered).
 *
 * The text and button are rendered in a React fragment, without any layout.
 *
 * @param maxLength
 * @param showMoreText
 * @param showLessText
 *
 * @param children {string}
 * Must be a single string. The string may contain raw HTML, although this HTML must begin
 * with an HTML tag.
 *
 * @param textProps {TextProps}
 * Passed directly to the freezer `<Text>` element that renders the children.
 */
const CondensedWithShowMore = ({
  maxLength,
  showMoreText = 'Show More',
  showLessText = 'Show Less',
  children,
  ...textProps
}: ShowMoreProps) => {
  const [showingMore, setShowingMore] = React.useState(false)
  const isHtml = children.match(/^<\w>/)
  const shown = showingMore
    ? children
    : // if it's html we know it starts with an html tag (3 chars), so we disregard
      // the tag and only pay attention to the length of the remaining text.
      trimToLength(children, maxLength + (isHtml ? 3 : 0))

  return (
    <>
      <Text {...textProps}>
        {isHtml ? <span dangerouslySetInnerHTML={{ __html: shown }} /> : shown}
      </Text>

      {children.length > maxLength && (
        <Button onClick={() => setShowingMore(!showingMore)} variant={'text'}>
          {!showingMore ? showMoreText : showLessText}
        </Button>
      )}
    </>
  )
}

export default CondensedWithShowMore
