import { JustCookRecipe } from '~/bb-api/schemata'
import { AvailableRecipe } from '~/routes/RecipePage/fixtures/recipe.fixture'

// for now we're just mocking up this page, and we don't
// know where the list of related recipes will come from,
// so we're just hardcoding their data here.
const recipeOptions: Record<string, JustCookRecipe> = {
  backToSchool: {
    id: 24376,
    link:
      'https://justcook.butcherbox.com/back-to-school-in-denial-about-fall-grill-mode-for-life-home-cooking/',
    title: {
      rendered:
        'Back-to-School, In Denial About Fall, Grill Mode for Life &#8211; Home Cooking Part 3',
    },
    excerpt: {
      rendered:
        '<p>We’re officially back-to-school this week, but I refuse to yield to fall just yet, despite the chill in the air in the evenings. There are still tomatoes and corn to be eaten! Needless to say, the first full week back to school can be a long one. Even if the [&hellip;]</p>\n',
    },
    jetpack_featured_media_url:
      'https://justcook.butcherbox.com/wp-content/uploads/2019/09/Leigh3coulotte.jpg',
  },
  byAnyNameCoulotte: {
    id: 16206,
    link:
      'https://justcook.butcherbox.com/picanha-coulotte-is-a-cut-everyone-can-enjoy/',
    title: {
      rendered:
        'By Any Name, Coulotte, Top Sirloin Cap, or Picanha, This is a Cut Everyone Can Enjoy',
    },
    excerpt: {
      rendered:
        '<p>A hidden delight of being a ButcherBox member is the monthly unpacking. Just check out one of the hundreds of videos people have posted online discovering the cuts included in each month&#8217;s shipment. One of the reasons the unboxing can be so exciting is because we include unique, difficult to find, [&hellip;]</p>\n',
    },
    jetpack_featured_media_url:
      'https://justcook.butcherbox.com/wp-content/uploads/2018/07/picanha.jpg',
  },
  howToCookSirloinCap: {
    id: 28705,
    link: 'https://justcook.butcherbox.com/how-to-cook-top-sirloin-cap/',
    title: {
      rendered: 'How to Cook Top Sirloin Cap',
    },
    excerpt: {
      rendered:
        '<p>Top sirloin cap is a lesser-known cut that we&#8217;re fond of here at Butcher Box. It comes in a small 1.5-pound roast, and, due to its petite size, it can be treated like a roast and cooked whole, or sliced into steaks and grilled or seared in a cast-iron skillet. [&hellip;]</p>\n',
    },
    jetpack_featured_media_url:
      'https://justcook.butcherbox.com/wp-content/uploads/2022/04/roasted-top-sirloin-cap.jpg',
  },
  smokyChiliRubbedCoulotte: {
    id: 207,
    link:
      'https://justcook.butcherbox.com/smoky-chili-rubbed-coulotte-with-fancy-red-pepper-salsa/',
    title: {
      rendered: 'Smoky Chili-Rubbed Coulotte with Fancy Red Pepper Salsa',
    },
    excerpt: {
      rendered:
        '<p>This flavorful, beefy cut, which is also known as the Brazilian picanha or the top sirloin cap, is roasted with a smoky spice blend.  A refreshing red pepper salsa balances out the sweet heat and savory flavors.</p>\n',
    },
    jetpack_featured_media_url:
      'https://justcook.butcherbox.com/wp-content/uploads/2019/02/Smoky-Chili-Rubbed-Coulotte-with-Fancy-Red-Pepper-Salsa.jpg',
  },
  castItonCoulotte: {
    id: 26210,
    link:
      'https://justcook.butcherbox.com/cast-iron-coulotte-with-chimichurri-sauce/',
    title: {
      rendered: 'Cast-Iron Coulotte with Chimichurri Sauce',
    },
    excerpt: {
      rendered:
        '<p>This easy recipe has delicious coulotte with chimichurri sauce on the table in under 30 minutes. Grab your faithful cast-iron and get cooking! Originating from Argentina and a specialty of Uruguay, chimichurri is an uncooked, parsley-based sauce that&#8217;s often served alongside grilled meat.</p>\n',
    },
    jetpack_featured_media_url:
      'https://justcook.butcherbox.com/wp-content/uploads/2020/09/chimichurri-coulotte.jpg',
  },
}

export const relatedRecipes: Partial<
  Record<AvailableRecipe, JustCookRecipe[]>
> = {
  'smoky-chili-rubbed-coulotte-with-fancy-red-pepper-salsa': [
    recipeOptions.backToSchool,
    recipeOptions.byAnyNameCoulotte,
    recipeOptions.howToCookSirloinCap,
  ],
  'top-sirloin-roast-with-herbes-de-provence': [
    recipeOptions.byAnyNameCoulotte,
    recipeOptions.smokyChiliRubbedCoulotte,
    recipeOptions.castItonCoulotte,
  ],
}
