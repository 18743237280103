import { Box, Text, IconCastIron, rem } from '@butcherbox/freezer'
import React from 'react'
import * as Styles from './RecipesLoading.css'

const RecipesLoading: React.FC = () => (
  <Box className={Styles.LoadingContainer}>
    <Text color="slate" component="div" variant="H2Bold">
      Coming right up...
    </Text>
    <Box style={{ height: rem(110), width: 'auto' }}>
      <IconCastIron
        customColor={{ base: 'slate', accent: 'spicedCrimson' }}
        display={'inline'}
        size={'scaleY'}
      />
    </Box>
  </Box>
)

export default RecipesLoading
