import React from 'react'
import { Box, Text } from '@butcherbox/freezer'
import { RecipeInfo } from '~/routes/RecipePage/recipe.types'
import * as Styles from './RecipePage.css'
import { Break_RecipePage, RelatedRecipesSection } from './RecipePage.css'
import { PanelBreak } from '~/components/Panel/PanelBreak/PanelBreak'
import RecipeGrid from '~/components/RecipeList/RecipeGrid'
import { relatedRecipes } from '~/routes/RecipePage/fixtures/relatedRecipes.fixture'
import RecipeUI from '~/routes/RecipePage/RecipeUI'
import { LayoutContext } from 'design/contexts/layout'
import DealCard from '~/components/DealCard/DealCard'
import { Deal } from '~/bb-api/schemata'
import useUpcomingOrder from '~/hooks/useUpcomingOrder'

export const RecipePageUI = ({
  recipe,
  deal,
}: {
  recipe: RecipeInfo
  deal: Deal
}) => {
  const { isDesktop } = React.useContext(LayoutContext)

  const { data: upcomingOrder } = useUpcomingOrder()

  // Create a map for faster lookup of quantity in individual deal cards
  const upcomingOrderMap = React.useMemo(
    () =>
      upcomingOrder?.invoiceItems.reduce((obj, invoiceItem) => {
        obj[invoiceItem.sku] = invoiceItem
        return obj
      }, {}) || {},
    [upcomingOrder?.invoiceItems]
  )

  return (
    <>
      <RecipeUI recipe={recipe} />
      <Box background="ivory" className={Styles.Page}>
        <Box alignItems="center" className={Styles.Content} paddingBottom={60}>
          <PanelBreak className={Break_RecipePage} intensity={'light'} />
          <Box paddingBottom={24} textAlign={'center'} w={'100%'}>
            <Text variant="H2Bold">Add Sirloin Cap to Your Next Box</Text>
          </Box>
          {deal && (
            <DealCard
              deal={deal}
              displayPastDueModal={() => {}}
              displayViewIngredientsLink={true}
              horizontal={isDesktop}
              idx={0}
              subscriptionStatus={'active'}
              upcomingOrderMap={upcomingOrderMap}
            />
          )}
        </Box>
      </Box>
      <Box background="white" className={Styles.Page}>
        <Box className={Styles.Content}>
          <Box className={RelatedRecipesSection}>
            <Box paddingBottom={24} textAlign={'center'} w={'100%'}>
              <Text variant="H2Bold">Shop Related Recipes</Text>
            </Box>
            <RecipeGrid
              cardWidth="large"
              columns={{ desktop: 3, tablet: 2, mobile: 1 }}
              gridGap={24}
              recipes={relatedRecipes[recipe.recipe.slug.split('wprm-').pop()]}
              recipesLoading={false}
            />
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default RecipePageUI
