import React from 'react'
import { RecipeGridProps } from '~/components/RecipeList/RecipeList.types'
import useSavedRecipeIds from '~/hooks/useSavedRecipeIds'
import RecipeGridUI from '~/components/RecipeList/RecipeGrid.ui'

/**
 * Provides a responsive grid layout, and manages the loading UI, for a list of recipes (rendered internally by a
 * `RecipeList`)
 */
const RecipeGrid = ({
  columns,
  gridGap,
  recipesLoading,
  ...recipeListProps
}: RecipeGridProps) => {
  const { isLoading: savedRecipesLoading } = useSavedRecipeIds()

  return (
    <RecipeGridUI
      columns={columns}
      gridGap={gridGap}
      isLoading={recipesLoading || savedRecipesLoading}
      {...recipeListProps}
    />
  )
}

export default RecipeGrid
